/* font smoothing */
@font-face {
    font-family: 'Gilroy';
    src: url('./fonts/Gilroy-Light.woff2') format('woff2'),
        url('./fonts/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./fonts/Gilroy-ExtraBold.woff2') format('woff2'),
        url('./fonts/Gilroy-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

html {
  height: 100vh;
}

.chord-box {
  background: #eff1f3;
  width: 25%;
  height: 89.8vh; /* this is pretty hacky */
  float: left;
  text-align: center;
  opacity:.8;
}

.h1-header {
  color: #CDCDCD;
  font-size: 24px;
  text-align: center;
  margin: 0;
}

.chords {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
}

.chord-display {
  margin: 0 auto;
}

.chord-val-key {
  font-size: 64px;
  height: 64px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 700;
  margin: 0;
  opacity: .9;
}

.chord-val-type {
  font-size: 32px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;
  opacity: .8;
}

.btn-lock {
  display: block;
  position: absolute;
  bottom: 16px;
  margin: 0 auto;
  left: 0;
  right: 0;
  font-size: 32px;
  opacity: .6;
}

.btn-lock:hover {
  cursor: grab;
  opacity: .4;
}

.chord-val {
  display: block;
  position: absolute;
  bottom: 16px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.p-instructions {
  text-align: center;
  padding: 8px 0 0 0;
  font-family: 'Manjari', sans-serif;
  font-size: 32px;
  color: #c0c0c0;
  margin: 0;
}

.explore-bar {
  background-color: #333333;
}

.explore-bar h1 {
  color: #FFFFFF;
  font-family: 'Gilroy', sans-serif;
  font-weight: 800;
  font-size: 24px;
  padding: 16px 8px 0 0;
  opacity: .92;
}

.explore-bar li {
  color: #333;
  font-family: 'Gilroy', sans-serif;
  font-weight: 700;
  padding: 0 0 0 8px;
  background: #E0EAFC;
  background: -webkit-linear-gradient(left, #CFDEF3, #E0EAFC);
  background: -o-linear-gradient(left, #CFDEF3, #E0EAFC);
  background: linear-gradient(to right, #CFDEF3, #E0EAFC);
  margin: 16px 0;
  border-radius: 8px;
}

.navbar-link {
  list-style-type: none;
  padding: 0;
  font-family: 'Gilroy', sans-serif;
  font-weight: 800;
  padding: 0 16px;
}

.form-header {
  font-family: 'Gilroy', sans-serif;
  padding: 16px 0 0 0;
  font-weight: 800;
  font-size: 32px;
}

.form-signin, .form-signup {
  padding: 0 32px 16px 32px;
}

.p-signin {
  padding: 0 32px;
  margin: 8px 0;
}

.btn-user {
  font-family: 'Gilroy', sans-serif;
  font-weight: 700;
  opacity: .9;
}

.form-signup label {
  margin: 2px 0;
}

