.navbar {
	height: 10vh;
	padding: 0 !important;
	margin: 0 !important;
}	

.nav-brand {
	font-family: 'Gilroy', sans-serif;
	padding: 0 !important;
	margin: 0 !important;
	font-weight: 800;
	color: '#333333';
	font-size: 48px !important;
}

.nav-instructions {
	font-family: 'Gilroy', sans-serif;
	font-weight: 800;
	color: #5C5C5C;
	font-size: 16px;
	margin: 0 16px 0 32px;
}